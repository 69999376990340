import { track } from '../../analytics/events';

export const desktopAnalytics = () => ({
  // FORM EVENTS
  form: {
    submitted: () => track('formSubmitted', {
      formType: 'HOME',
      formName: 'HOME',
    }),
  },
  // CLICK EVENTS
  click: {
    getStarted: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Get Started',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
});

export const mobileAnalytics = () => ({
  // CLICK EVENTS
  click: {
    getStarted: () => track('ElementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Get Started',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
});
