<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main class="cancel-mobile u-container">
      <header>
        <h1
          id="cancel-heading"
          class="font-bold text-2xl"
        >
          Cancel Your Change-of-Address Order
        </h1>
        <p class="mt-3">
          <strong>Note:</strong> Canceling your Change-of-Address order cannot be undone. If you cancel your Change-of-Address and still need to forward your mail, a new Change-of-Address must be submitted.
        </p>
        <ErrorAlert
          v-if="errors.cancelReason.hasError"
          id="alert-cancel-reason"
          ref="error"
          class="mt-4 errors"
        >
          {{ errors.cancelReason.message }}
        </ErrorAlert>
        <ErrorAlert
          v-if="numberOfEmfExtensions"
          id="alert-coac-emf"
          ref="error-coac-emf"
          class="mt-4 errors"
        >
          If you cancel your Change-of-Address order, the Extended Mail Forwarding fees will not be refunded. Extended Mail Forwarding service is nonrefundable.
        </ErrorAlert>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate(); analytics.form.submitted();"
      >
        <section>
          <h2 class="font-bold text-xl mt-3">
            Why do you want to cancel your Change-of-Address?
          </h2>
          <InputRadio
            id="cancel-reason-move"
            v-model="cancelReason"
            form-value="CAN"
            name="cancel-reason"
            class="mt-3"
            :has-error="errors.cancelReason.hasError"
            @input="analytics.input.decidedNotToMove()"
          >
            <span class="text-black">I decided not to move</span>
          </InputRadio>
          <InputRadio
            id="cancel-reason-forward"
            v-model="cancelReason"
            form-value="FRD"
            name="cancel-reason"
            class="mt-3"
            :has-error="errors.cancelReason.hasError"
            @input="analytics.input.didNotAskPostal()"
          >
            <span class="text-black">I did not ask the Postal Service to forward my mail</span>
          </InputRadio>
          <InputRadio
            id="cancel-reason-name"
            v-model="cancelReason"
            form-value="NAME"
            name="cancel-reason"
            class="mt-3"
            :has-error="errors.cancelReason.hasError"
            @input="analytics.input.unableToMakeChangesName()"
          >
            <span class="text-black">I was unable to make changes to my name</span>
          </InputRadio>
          <InputRadio
            id="cancel-reason-address"
            v-model="cancelReason"
            form-value="ADDR"
            name="cancel-reason"
            class="mt-3"
            :has-error="errors.cancelReason.hasError"
            @input="analytics.input.couldntChangeAddress()"
          >
            <span class="text-black">Could not make the change to my address that I wanted</span>
          </InputRadio>
        </section>
        <div class="c-hairline mt-6" />
        <section class="my-6">
          <p>Canceling your Change-of-Address order cannot be undone</p>
          <router-link
            id="nevermind-dont-cancel"
            ref="nevermind-dont-cancel"
            :to="{ name: 'review', query: { notCanceled: true } }"
            class="c-link"
            @click.native="analytics.click.dontCancelOrder()"
          >
            Never mind, don't cancel my order
          </router-link>
          <button
            id="cancel-order"
            ref="submit"
            class="c-btn w-full mt-6"
            @click="analytics.click.cancelMyOrder()"
          >
            Cancel My Order
          </button>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  ErrorAlert,
  InputRadio,
} from '@mgo/ui/lib/components';
import { mobileAnalytics } from './analytics';
import cancelMixin from './mixin';

export default {
  name: 'Cancel',
  components: {
    AppLoader,
    ErrorAlert,
    InputRadio,
  },
  mixins: [cancelMixin],
  computed: {
    analytics() {
      return mobileAnalytics();
    },
  },
};
</script>
