import { object } from 'yup';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import validators from './validations';

export default {
  data() {
    return {
      loading: false,
      lazy: true,
      cfpsid: '',
      zipcode: '',
      error: '',
    };
  },
  computed: {
    flagCoaDisputesEnabled() {
      return this.$store.get('config@coaDisputesEnabled') || false;
    },
    flagCoacExtendedFwdEnabled() {
      return this.$store.get('config@coacExtendedFwdEnabled') || false;
    },
    flagShowGpOutageBanner() {
      return this.$store.get('config@showGpOutageBanner') || false;
    },
    upcomingMaintenance() {
      return this.$store.get('config@upcomingMaintenance') || '';
    },
    rules() {
      return object().shape(validators);
    },
    errors() {
      return mapErrors(this, ['cfpsid', 'zipcode']);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
  },
  async mounted() {
    this.$coac.reset();
    localStorage.removeItem('ampersend_data');
    this.$coac.visitId = JSON.parse(sessionStorage.getItem('visitId'));
  },
  methods: {

    async validate() {
      if (this.loading) return;

      this.error = '';
      this.lazy = false;
      await this.$nextTick();

      if (this.formIsValid) {
        this.submit();
      }
    },
    async submit() {
      this.loading = true;
      this.error = await this.$coac.login(this.cfpsid, this.zipcode);

      if (!this.error) {
        this.next();
      }

      this.loading = false;
    },
    next() {
      this.$router.push({ name: 'review' });
    },
  },
};
