import { track } from '../../analytics/events';

export const desktopAnalytics = () => ({
  // FORM EVENTS
  form: {
    submitted: () => track('formSubmitted', {
      formType: 'CANCEL',
      formName: 'CANCEL',
    }),
  },
  // CLICK EVENTS
  click: {
    cancelMyOrder: () => track('elementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'SUBMIT',
    }),
    dontCancelOrder: () => track('elementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Never mind, don`t cancel my order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
  // INPUT EVENTS
  input: {
    decidedNotToMove: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I decided not to move',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    didNotAskPostal: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I did not ask Postal Service to forward my mail',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    unableToMakeChangesName: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I was unable to make changes to my name',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    couldntChangeAddress: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'Could not make the change to my address that I wanted',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
  },
});

export const mobileAnalytics = () => ({
  // CLICK EVENTS
  click: {
    cancelMyOrder: () => track('elementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Cancel My Order',
      },
      actionOutcome: 'SUBMIT',
    }),
    dontCancelOrder: () => track('elementClicked', {
      webElement: {
        elementType: 'BUTTON',
        text: 'Never mind, don`t cancel my order',
      },
      actionOutcome: 'INTERNALLINK',
    }),
  },
  // INPUT EVENTS
  input: {
    decidedNotToMove: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I decided not to move',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    didNotAskPostal: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I did not ask Postal Service to forward my mail',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    unableToMakeChangesName: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'I was unable to make changes to my name',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
    couldntChangeAddress: () => track('FieldInteracted', {
      userInputField: {
        fieldType: 'RADIO',
        fieldName: 'cancel-reason',
        fieldValue: 'Could not make the change to my address that I wanted',
      },
      formContext: {
        formName: 'CANCEL',
      },
    }),
  },
});
