import { object } from 'yup';
import {
  mapErrors,
  objectIsValid,
} from '@mgo/core/src/validations/utils';
import validators from './validations';

export default {
  data() {
    return {
      cancelReason: '',
      numberOfEmfExtensions: 0,
      lazy: true,
      loading: true,
    };
  },
  computed: {
    rules() {
      return object().shape(validators);
    },
    errors() {
      const fieldsToValidate = ['cancelReason'];

      return mapErrors(this, fieldsToValidate);
    },
    formIsValid() {
      return objectIsValid(this.errors);
    },
  },
  async mounted() {
    // we can't use destructuring because Jest hates it for some mind-boggling reason -_-
    const search = await this.$coac.search();
    // set the EMF extension number for alert banner
    this.numberOfEmfExtensions = search?.extInfo?.numExtensions;
    if (!this.coaIsCancellable(search)) {
      this.$router.replace({ name: 'review' });
    }
    this.loading = false;
  },
  methods: {
    coaIsCancellable(coa) {
      return !coa?.processing
        && !coa?.eodRunning;
    },
    async validate() {
      this.lazy = false;
      await this.$nextTick();
      if (!this.formIsValid) {
        return this.scrollToFirstError();
      }
      return this.submit();
    },
    async scrollToFirstError() {
      const errorHTML = document.querySelector('.errors');
      if (errorHTML !== null) {
        setTimeout(() => {
          errorHTML.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    },
    async submit() {
      const { error } = await this.$coac.cancel(this.cancelReason);

      if (!error) {
        this.$router.replace({ name: 'confirm-cancel', query: { reason: this.cancelReason } });
      }
    },
  },
};
