import { string } from 'yup';
import { cfpsid } from '@mgo/core/src/validations/coac';

const zipcode = string()
  .required('The New ZIP Code™ field cannot be left blank.')
  .test(
    'min-max',
    'The New ZIP Code™ field should contain 5 numbers.',
    (value) => {
      if (value) {
        return value.length === 5;
      }

      return true;
    },
  );

export default {
  cfpsid,
  zipcode,
};
