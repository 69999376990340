<template>
  <main class="u-container">
    <header>
      <h1
        id="confirm-cancel-heading"
        class="font-bold text-2xl"
      >
        Your Change-of-Address order has been canceled.
      </h1>
    </header>
    <section>
      <h2 class="font-bold text-xl mt-3">
        You will receive a confirmation shortly.
      </h2>
      <p class="mt-3">
        Depending on the original mail forwarding start date of your order, you may still receive an Official Change-of-Address Confirmation in the mail. You may disregard this notice.
      </p>
      <p class="mt-3">
        When you cancel your Change-of-Address order, you are informing the United States Postal Service that you have decided not to forward your mail as requested.
      </p>
      <p class="mt-3">
        <strong>Please note:</strong> The identity verification fee will not be automatically refundable, and therefore may still apply.
      </p>
    </section>
    <div class="c-hairline mt-6" />
    <section class="my-6">
      <a
        :href="`${$options.mgoUrl}/mgo/disclaimer`"
        class="c-link block"
      >
        Need to submit a new Change-of-Address Order?
      </a>
      <a
        :href="`${$options.coacUrl}/mgoc/home`"
        class="c-link block mt-2"
      >
        Need to view, edit or cancel another Change-of-Address?
      </a>
    </section>
  </main>
</template>

<script>
import cancelConfirmMixin from './mixin';
import destinationManagerMixin from '../../mixins/destinationManager';

export default {
  name: 'ConfirmCancel',
  mixins: [cancelConfirmMixin, destinationManagerMixin],
};
</script>
