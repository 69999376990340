<template>
  <div>
    <AppLoader
      v-if="loading"
      modal
    />
    <main class="cancel-desktop u-container">
      <header>
        <h1
          id="cancel-heading"
          class="font-bold"
        >
          Cancel Your Change-of-Address Order
        </h1>
        <ErrorAlert
          v-if="errors.cancelReason.hasError"
          id="alert-cancel-reason"
          ref="error"
          class="mt-4 errors"
        >
          {{ errors.cancelReason.message }}
        </ErrorAlert>
        <ErrorAlert
          v-if="numberOfEmfExtensions"
          id="alert-coac-emf"
          ref="error-coac-emf"
          class="mt-4 errors"
        >
          If you cancel your Change-of-Address order, the Extended Mail Forwarding fees will not be refunded. Extended Mail Forwarding service is nonrefundable.
        </ErrorAlert>
      </header>
      <div class="c-hairline mt-6" />
      <form
        ref="form"
        novalidate
        @submit.prevent="validate(); analytics.form.submitted();"
      >
        <section class="flex my-6">
          <div class="w-1/2 pr-xl">
            <h2 class="font-bold text-2xl">
              Why do you want to cancel your Change-of-Address?
            </h2>
            <InputRadio
              id="cancel-reason-move"
              ref="move"
              v-model="cancelReason"
              form-value="CAN"
              name="cancel-reason"
              class="mt-1"
              :has-error="errors.cancelReason.hasError"
              @input="analytics.input.decidedNotToMove()"
            >
              <span class="text-black">I decided not to move</span>
            </InputRadio>
            <InputRadio
              id="cancel-reason-forward"
              ref="forward"
              v-model="cancelReason"
              form-value="FRD"
              name="cancel-reason"
              class="mt-1"
              :has-error="errors.cancelReason.hasError"
              @input="analytics.input.didNotAskPostal()"
            >
              <span class="text-black">I did not ask the Postal Service to forward my mail</span>
            </InputRadio>
            <InputRadio
              id="cancel-reason-name"
              ref="name"
              v-model="cancelReason"
              form-value="NAME"
              name="cancel-reason"
              class="mt-1"
              :has-error="errors.cancelReason.hasError"
              @input="analytics.input.unableToMakeChangesName()"
            >
              <span class="text-black">I was unable to make changes to my name</span>
            </InputRadio>
            <InputRadio
              id="cancel-reason-address"
              ref="address"
              v-model="cancelReason"
              form-value="ADDR"
              name="cancel-reason"
              class="mt-1"
              :has-error="errors.cancelReason.hasError"
              @input="analytics.input.couldntChangeAddress()"
            >
              <span class="text-black">Could not make the change to my address that I wanted</span>
            </InputRadio>
          </div>
          <div class="w-1/2">
            <h3 class="font-bold">
              More Information
            </h3>
            <p class="mt-3">
              <strong>Note:</strong> Canceling your Change-of-Address order cannot be undone. If you cancel your Change-of-Address and still need to forward your mail, a new Change-of-Address must be submitted.
            </p>
          </div>
        </section>
        <div class="c-hairline mt-6" />
        <section class="flex my-6">
          <button
            id="cancel-order"
            ref="submit"
            class="c-btn"
            @click="analytics.click.cancelMyOrder()"
          >
            Cancel My Order
          </button>
          <div class="ml-6">
            <p>This cannot be undone</p>
            <router-link
              id="nevermind-dont-cancel"
              ref="nevermind-dont-cancel"
              :to="{ name: 'review', query: { notCanceled: true } }"
              class="c-link"
              @click.native="analytics.click.dontCancelOrder()"
            >
              Never mind, don't cancel my order
            </router-link>
          </div>
        </section>
      </form>
    </main>
  </div>
</template>

<script>
import {
  AppLoader,
  ErrorAlert,
  InputRadio,
} from '@mgo/ui/lib/components';
import { desktopAnalytics } from './analytics';
import cancelMixin from './mixin';

export default {
  name: 'Cancel',
  components: {
    AppLoader,
    ErrorAlert,
    InputRadio,
  },
  mixins: [cancelMixin],
  computed: {
    analytics() {
      return desktopAnalytics();
    },
  },
};
</script>
